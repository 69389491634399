<template>
    <modal ref="modalSubirArchivo" titulo="Subir archivo" no-aceptar :cargado-modal="loading" :btns="botones" no-cancelar @accion="accionModal">
        <div v-show="!borrador" class="row mx-0 align-items-center py-3 px-4">
            <el-upload
            ref="upload"
            action="#"
            :on-change="changeFile"
            :on-remove="removeFile"
            :auto-upload="false"
            :multiple="false"
            :limit="1"
            accept=".xlsx"
            >
                <div class="bg-general2 text-white br-8 px-3 py-1 f-16 cr-pointer shadow">
                    Seleccionar archivo
                </div>
            </el-upload>
            <p class="mx-0 f-14 text-general">Solo se aceptan archivos con extención ( .xlsx )</p>
        </div>
        <div v-show="borrador" class="row justify-center text-general">
            <p> Tienes un proceso de importación de productos pendiente </p>
            <p> ¿Desea continuarlo? </p>
        </div>
    </modal>
</template>
<script>
import Productos from "~/services/productos/productosAdmin";

export default {
    data(){
        return {
            loading:false,
            file:null,
            borrador:false,
            remplazar:false
        }
    },
    computed:{
        idCedis(){
            return Number(this.$route.params.id_cedis)
        },
        botones(){
            if(this.borrador){
                return [
                    { texto:"Descartar", color:"btn-cancelar", accion:"descartar" },
                    { texto:"Continuar", color:"btn-general", accion:"continuar" }
                ]
            }
            return [
                { texto:"Cerrar", color:"btn-cancelar", accion:"cerrar" },
                { texto:"Cargar", color:"btn-general", accion:"cargar" }
            ]
        }
    },
    methods: {
        async setImportarProdutos(){
            try {

                const {data} = await Productos.checkImportacion()
                if(data.borrador){
                    this.borrador = true
                }
                this.remplazar = false
                this.$refs.upload.clearFiles()
                this.$refs.modalSubirArchivo.toggle();

            } catch (error){
                this.error_catch(error)
            }
        },
        accionModal(accion){
            switch (accion){
            case 'descartar':
                this.eliminarBorrador()
                break;
            case 'continuar':
                this.$refs.modalSubirArchivo.toggle();
                this.$router.push({
                    name: 'admin.cedis.importar.productos.temp',
                    params:{
                        id_cedis:this.idCedis,
                        idUser:this.$usuario.id
                    }
                })
                break;
            case 'cargar':
                this.cargar()
                break;

            default:
                this.$refs.modalSubirArchivo.toggle();
                break;
            }
        },
        async eliminarBorrador(){
            try {

                const {data} = await Productos.eliminarArchivoBorrador()
                this.borrador = false

            } catch (error){
                this.error_catch(error)
            }
        },
        toggle(){
            this.$refs.upload.clearFiles()
            this.$refs.modalSubirArchivo.toggle();
        },
        editar(){
            this.borrador = false
            this.remplazar = true
            this.$refs.upload.clearFiles()
            this.$refs.modalSubirArchivo.toggle();
        },
        changeFile(file){
            this.file = file.raw
        },
        removeFile(){
            this.file = null
        },
        async cargar(){
            try {
                if(this.file === null) return
                let form = {
                    id_cedis:this.idCedis,
                    file:this.file
                }
                this.loading =  true
                form = this.crear_formData(form)
                const {data} = await Productos.importarProductosMasivo(form)
                this.$refs.modalSubirArchivo.toggle();

                if(this.remplazar){
                    this.$emit('update')
                    return
                }

                this.$router.push({
                    name: 'admin.cedis.importar.productos.temp',
                    params:{
                        id_cedis:this.idCedis,
                        idUser:this.$usuario.id
                    }
                })
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading =  false
            }

        },

    }
}
</script>
